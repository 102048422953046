<template>
  <!-- 选择课件模板 -->
  <div class="choose_template">
    <van-radio-group v-model="radio">
      <van-cell-group>
        <van-cell clickable @click="radio = '1'" class="card_outer">
          <template #title>
            <div class="template_box">
              <div class="img_box">
                  <van-image width="135" height="82" src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
              </div>
              <div class="text_box">【病例分享】多囊卵巢综合症（PCOS）1例</div>
            </div>
          </template>

          <template #right-icon class="icon_box">
            <van-radio name="1" />
          </template>
        </van-cell>
      </van-cell-group>

      <van-cell-group>
        <van-cell clickable @click="radio = '2'" class="card_outer">
          <template #title>
            <div class="template_box">
              <div class="img_box">
                  <van-image width="135" height="82" src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
              </div>
              <div class="text_box">【病例分享】多囊卵巢综合症（PCOS）1例</div>
            </div>
          </template>

          <template #right-icon class="icon_box">
            <van-radio name="2" />
          </template>
        </van-cell>
      </van-cell-group>
     <!-- 弹窗 -->
    <van-dialog v-model="show" title="修改课件名称" show-cancel-button cancelButtonText="下载PPT" confirmButtonText="提交录制" confirm-button-color="#2788FF" @confirm="Submit()" @cancel="DownLoadPPT()"> 
      <input type="text" class="ipt_box" placeholder="【病例分享】异常子宫出血（AUB）1例">
    </van-dialog>
    </van-radio-group>
    <div class="btn">
        <van-button round type="info" size="large" class="confirm_btn" @click="Confirm()">确认</van-button>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import { RadioGroup, Radio } from 'vant';

Vue.use(Radio);
Vue.use(RadioGroup);
export default {
  data(){
    return {
      radio: '',
      show: false,
    }
  },
  methods: {
    // radio(){

    // },
    Confirm(){
     this.show = true
    },
    // 提交录制
    Submit(){
      console.log("提交录制")
    },
    // 下载PPT
    DownLoadPPT(){
      console.log("下载PPT");
      this.$router.push({
        path:"download_center"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.choose_template{
  .van-radio-group{
    margin: 10px;
    .van-radio{
      margin-right: 8px;
    }
  }
  .card_outer{
    padding: 0;
    // margin-right: 20px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    margin-bottom: 15px;
    .template_box{
      display: flex;
      align-items: center;
      // background: #ccc;
      .img_box{
        display: flex;
        padding: 9px;
      }
      .text_box{

      }
    }
  }
  
  
  .btn{
    .van-button__text{
      font-size: 20px;
    }
    .confirm_btn{
      width: 90%;
      height: 43px;
    }
  }
  .ipt_box{
    width:90%;
    border: 1px solid #BBBBBB;
    padding: 5px;
    margin: 32px auto;
  }
  
}
</style>